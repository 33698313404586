.iconStyle {
    color: rgb(68, 11, 4);
}

.personItem {
    margin: 0 auto;
}

.authors {
    color: rgb(68, 11, 4);
}