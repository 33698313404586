.headBar {
    display: flex;
    background-color: transparent !important;
    box-shadow: none !important;
    background-position-y: 0;
    align-items: center;
}

.headBar .BarIn {
    /* flex-grow: 1; */
    /* height: 70px; */
    height: 150px;
    width: 4000px;
    background: url(../../assets/img/Header/menu-bar-in.webp) no-repeat center;
    /* background-size: 100 100;  */
    align-items: center;
    background-position-y: 0;
    background-size: contain;
    background-color: transparent !important;
    box-shadow: none !important;
    position: relative;
    z-index: 4;
}

.headBar .BarOut {
    height: 59.9px;
    background: url(../../assets/img/Header/menu-bar-out.webp) repeat-x;
    background-size: contain;
    align-items: center;
    background-position-y: 0;
    background-color: transparent !important;
    box-shadow: none !important;
}

.headBar .headerTitle {
    margin-top: 2px;
    display: flex;
    align-items: center;
    vertical-align: center;
}

.headBar .headerFont {
    height: 70px;
    vertical-align: center;
}

/* Menu */

.MenuBar {
    position: absolute;
    top: -150px;
    left: 44.2%;
    background-color: transparent !important;
    box-shadow: none !important;
}

.MenuUp {
    position: relative;
    height: 110px;
    z-index: 5;
}

.MenuDown {
    height: 97px;
    z-index: 3;
    visibility: hidden;
}

.MenuBarBot {
    margin-top: -39px;
    margin-left: 49px;
    background: url(../../assets/img/Header/menu/menu-down.webp) no-repeat center;
    background-size: contain;
    background-color: transparent !important;
    height: 97px;
    width: 377.933px;
    z-index: 3;
    display: flex;
    position: absolute;
}

.MenuHomeIcon {
    position: absolute;
    top: 10px;
    z-index: 6;
    left: 40%;
}

.MenuHomeIcon img {
    height: 80px;
}

.MenuIcons img {
    height: 60px;
}

.MenuCharIcon {
    margin-top: 17px;
    margin-left: 98px;
}

.MenuGalleryIcon {
    margin-top: 19px;
    margin-left: -24px;
}

.MenuScrollIcon {
    margin-top: 15px;
    margin-left: -20px;
}

/* End Menu */

.title {
    /* flex-grow: 1; */
    text-decoration: none;
    color: #e0ccb5;
    font-weight: 700;
    font-family: "DCTitleFont1";
    font-size: 30;
    text-align: center;
}

.title a {
    color: #e0ccb5;
    text-decoration: none;
}

@media (max-width: 900px) {
    .headBar {
        background-size: cover;
    }
}
