.footer {
    position: relative;
    /* bottom: -16; */
    background-image: url(../../assets/img/Footer/background.webp);
    /* background-size: "100% 100%"; */
    background-size: cover;
    padding-top: 2px;
    /* height: 155px; */
    height: 50px;
}

.contact {
    flex-shrink: 0;
    text-align: center;
    min-height: 50;
    color: #e0ccb5;
    fill: #e0ccb5;
    font-family: 'DCTitleFont1';
    font-size: 20px;
    margin-top: 2px;
}

.footerMessage {
    margin-bottom: -1px;
}

.emailBox {
    margin-bottom: 0px;
    font-family: 'DCTitleFont1';
    font-size: 18;
}

.emailBox a {
    color: #add8e6;
}

@media (max-width: 640px) {
    .footer {
        height: 25px;
    }
}
