.cardBorder {
    border-image: url(../../../../assets/img/Paper/episodeBorder.webp) 10 fill;
    border-style: solid;
    border-width: 10px;
    margin: 0.5%;
}

.cardDiv {
    /* display: absolute; */
    /* margin: 0.5%; */
    /* justify-content: center;  */
    padding-bottom: 50px;
    height: 410px;
    width: 320px;
    background-image: url(../../../../assets/img/Paper/episodeBackground.webp);
    background-size: 100% 100%;
    background-color: transparent !important;
}

.cardDiv a {
    text-decoration: none;
}

.boxShadow {
    transition: box-shadow 0.3s ease-in-out;
}

.boxShadow:hover {
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.75);
}

.card {
    padding-top: 25px;
}

.cover {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cover img {
    height: 85%;
    width: 85%;
    margin: 0 auto 0;
}

.cardContent {
    font-family: "DCTitleFont1";
    color: rgb(68, 11, 4);
    font-size: 23px;
    justify-content: center;
    margin: 8px 8px 0px;
    text-align: center;
}

.cardTitle {
    margin: 0 auto;
    width: 90%;
    max-height: 60px;
    text-align: center;
}

@media (max-width: 340px) {
    .cardDiv {
        padding-bottom: 50px;
        height: 340px;
        width: 280px;
    }
}
