.characterContainer {
    max-width: 170px;
    display: grid;
    justify-content: center;
    align-self: center;
    transition: transform .2s;
    filter: grayscale();
}

.characterContainer:hover {
    transform: scale(1.10, 1);
    filter: grayscale(0%);
}

.characterImage {
    max-width: 380px;
    position: relative;
    pointer-events: none;   
}

@media (max-width: 500px) {
    .characterImage {
        max-width: 200px;
        
    }

    .characterContainer {
        max-width: 70px;
    }
}
