.characterCanvasContainer {
    background-image: url(../../../assets/img/TopImage/fundo.webp);
    background-size: cover;
    background-position: 0% 65%;
    overflow: auto;
    display: grid;
    /* justify-content: center; */
    /* display: inline-block; */
    /* margin:auto; */
}

@media (max-width: 1600px) {
    .characterCanvasContainer {
        background-position: 0% 25px;
    }
}

.charactersList {
    display: inline-flex;
    margin: auto;
    align-content: center;
    text-align: center;
    justify-content: center;
}
