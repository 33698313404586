.socialForTop {
    position: relative;
    
    padding-top: 10px;
    padding-bottom: 5px;
    /* bottom: -16; */
    /* height: 150px; */
    
    /* background-size: cover; */
}

.contact {
    position: relative;
    
    padding-top: 5px;
    /* bottom: -16; */
    /* height: 150px; */
   
    background-image: url(../../../../assets/img/SocialBar/socialBar.webp);
    background-size: 100% 100%;
    background-repeat: no-repeat;
}