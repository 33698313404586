.patreonAgradecimento {
    text-align: center;
    background: url('../../assets/img/Patreon/madeira.webp');
    background-size: cover;
    padding-bottom: 55px;
    padding-top: 8%;
}

.patreonAgradecimento_img {
    max-height: 820px;
}

.patreonAlistar_img {
    max-width: 30%;
    min-width: 250px;
}

.qrcode_img {
    max-width: 30%;
    max-height: 420px;
}

.patreonContainerTop {
    text-align: center;
    background-size: 100% 100%;
    margin: 0 auto;
    height: 120px;
    display: inline-flexbox;
    margin-top: -35px;
}

.patreonContainerTop h1 {
    margin-top: -80px;
}

.patreonContainerBottom {
    background-size: 100% 100%;
    height: 80px;
    margin-top: -1px;
}

.patreonContainer {
    background-image: url(./../../assets/img/Paper/papel.webp);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    padding-top: 4%;
    padding-bottom: 3%;
    margin-top: -30px;
    text-align: center;
}

.patreonTitle {
    font-size: 44px;
    font-family: "DCTitleFont1";
    color: rgb(68, 11, 4);
    margin: 0 auto;
    padding-top: 1%;
}

.patreonNameFont {
    font-size: 28px;
    font-family: "DCTitleFont1";
    color: black;
    margin: 0 auto;
    padding-top: 1%;
}

@media (max-width: 900px) {
    .patreonAgradecimento_img {
        max-height: 600px;
        margin-top: 10%;
    }
    .qrcode_img {
        max-height: 250px;
    }
}

@media (max-width: 640px) {
    .patreonAgradecimento_img {
        max-height: 500px;
        margin-top: 20%;
    }
    .qrcode_img {
        max-height: 250px;
    }
}

@media (max-width: 520px) {
    .patreonAgradecimento_img {
        max-height: 370px;
        margin-top: 20%;
    }
    .qrcode_img {
        max-height: 250px;
    }
}

@media (max-width: 450px) {
    .patreonAgradecimento_img {
        max-height: 320px;
        margin-top: 35%;
    }
    .patreonContainerTop h1 {
        margin-top: -50px;
    }
    .patreonTitle {
        font-size: 36px;
    }
    .qrcode_img {
        margin: 10px;
    }
}
