@import '../PageHome/EpisodeGrid/EpisodeGrid.css';

.gridContainerTopUnder {
    background-image: url('../../assets/img/Footer/background.webp');
    height: 100px;
    margin-top: -50px;
    padding-top: 100px
}

.gridContainerTopSpotify {
    text-align: center;
    background-image: url('../../assets/img/Paper/papelTopo.webp');
    background-size: 100% 100%;
    margin:  0 auto;
    height: 120px;
    display: inline-flexbox;
}

.episodePage {
    display: block;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    /* margin-top: 70px; */
    padding-top: 90px;
    background-image: url(../../assets/img/Paper/papel.webp) !important;
    margin-top: -20px;
}

.title {
    position: flex;
    height: auto;
    font-size: 45px;
    font-family: 'DCTitleFont1';
    font-weight: bold;
    margin: 0 auto;
    color: rgb(68, 11, 4);
}

.youtubeCard {
    /* position: absolute; */
    /* top: 0;
    left: 0; */
    /* width: 90%;
    height: 90%; */
    margin: 0 auto;
    padding-top: 2%;
    padding-bottom: 2%;
    min-height: 270px;
    min-width: 270px;
    height: 500px;
    max-width: 500px;
    /* max-height: 800px; */
}

.fontSummary {
    font-size: 20px;
    color: rgb(68, 11, 4);
    padding: 1% 1% 1% 1%;
}

.summary {
    text-align: center;
    /* padding-bottom: 2%; */
    max-width: 1200px;
    position: relative;
    margin: 0 auto;
}

.container-summary {
    padding-bottom: 18px;
}

.summary a {
    color: #6e5a0a;
}

.Emoji {
    font-size: 18px;
    vertical-align: center;
    color: transparent;
    text-shadow: 0 0 0 rgb(68, 11, 4);
}

@media (min-width: 901px) {
    .credits {
        display: flex;
    }
    
    .creditsBlock {
        margin: 0 auto;
        min-width: 23%;
    }
}

@media (max-width: 900px) {
    .YoutubeCard {
        margin-top: -1%;
        padding-top: 4%;
    }

    .summary {
        margin-top: 10%;
    }
}

@media (max-width: 430px) {
    .title {
        font-size: 25px;
    }

    .YoutubeCard {
        margin-top: -10px;
        height: 350px;
    }

    .summary {
        margin-top: 5%;
    }
}
