.gridContainerTopDiv {
    background-image: url(../../../assets/img/Header/background.webp);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    padding-bottom: 35px;
    /* display: inline-flexbox; */
}

.social {
    color: #e0ccb5;
    fill: #e0ccb5;
    display: inline-block;
    width: 100%;
}

.gridContainerTop {
    /* background-image: url(./../../assets/img/Paper/papelTopo.png); */
    text-align: center;
    background-size: 100% 100%;
    margin: 0 auto;
    height: 120px;
    display: inline-flexbox;
    margin-top: -35px;
}

.titlePodcast {
    font-size: 44px;
    font-family: "DCTitleFont1";
    color: rgb(68, 11, 4);
    margin: 0 auto;
    padding-top: 1%;
    margin-top: -120px;
}

.gridContainer {
    background-image: url(../../../assets/img/Paper/papel.webp);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 100%;
    padding-top: 4%;
    padding-bottom: 3%;
    margin-top: -1px;
}

.loadingMessage{
    background-image: url(../../../assets/img/Paper/papel.webp);
    text-align: center;
}

.gridContainerBottom {
    /* background-image: url(); */
    background-size: 100% 100%;
    height: 80px;
    margin-top: -1px;
}

.EpisodesGrid {
    align-items: stretch;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    margin-top: -100px;
    /* equals top image height */
    padding-top: 40px;
}

.buttons {
    background-color: #C19A6B;
    margin-top: 4%;
    margin-bottom: -3%;
}

.buttonsDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

/*
@media (max-width: 640px) {
    .gridContainer {
        padding-top: 87px;
    }
} */

@media (max-width: 900px) {
    .EpisodesGrid {
        padding-top: 80px;
    }
}
