.iconStyle {
    color: rgb(68, 11, 4);
}

.iconStyle span {
    margin-top: -6px;
}

.citationItem {
    margin: 0 auto;
}

.citations {
    color: rgb(68, 11, 4);
}